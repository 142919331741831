import { useMainStore } from "@/mainStore";
import WebRTC from "@/irc/webrtc";

export function startBroadcasting(broadcastOption) {
    const store = useMainStore();
    store.toggleBroadcastOption();
    store.enableBroadcasting();
    if(broadcastOption.audio) {
        store.enableAudioButton();
    } else{
        store.disableAudioButton();
    }

    if(broadcastOption.video) {
        store.enableVideoButton();
    } else{
        store.disableVideoButton();
    }
    if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
            .getUserMedia(broadcastOption)
            .then(
                function (stream) {
                    var lv = document.getElementById("local-video-holder");
                    lv.srcObject = stream;
                    store.setLocalStream(stream);

                    store.socket.emit("broadcasting", {nick: store.userName, broadcasting: true });
                }.bind(this)
            )
            .catch(function (err) {
                console.log("Something went wrong!", err);
            });
    }else{
        alert("Your browser does not support video calling features.");
    }
}

export function joinBroadcast(user){
    const store = useMainStore();

    if(user == store.userName){
        alert("Sorry, can not join own broadcast!");
        return;
    }

    var connection = new WebRTC(user);
    connection.requestEntry();
}

export function stopBroadcasting(){
    const store = useMainStore();

    // store.setLocalStream(null);
    // store.localStream.srcObject.getTracks().forEach(function (track) {
    //   track.stop();
    // });

    if(store.localStream) {
        const tracks = store.localStream.getTracks();
        tracks.forEach(track => track.stop());
    }

    // Update the UI element (e.g., a video element) if needed
    var lv = document.getElementById("local-video-holder");
    if (lv && lv.srcObject) {
        lv.srcObject = null;
    }

    store.socket.emit("broadcasting", {nick: store.userName, broadcasting: false });
}

export function approveBroadcastEntry(user){
    const store = useMainStore();

    const connection = new WebRTC(user);
    connection.sendOffer();

    store.removeFromPendingPermission(user);
}

export function declineBroadcastEntry(user){
    const store = useMainStore();
    store.removeFromPendingPermission(user);
    store.socket.emit("declined", {
        from: store.userName,
        to: user
    })
}