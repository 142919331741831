<template>
    <div class="bg-gray-400/50 absolute top-0 h-screen w-screen flex justify-center overflow-y-auto z-10" id="modal-container">
        <div class="w-[100vw] md:w-[30rem] bg-white my-40 h-fit rounded-md p-2 text-black" ref="imageViewerModalRef" id="profileModal">
            <div class="flex justify-between items-center px-4 py-5">
                <p class="text-2xl">GIPHY/IMAGE</p>
                <XMarkIcon class="h-6 w-6 text-gray-500 hover:text-gray-800 cursor-pointer" @click="store.hideImageViewer" />
            </div>
            <hr>
            <div class="min-h-[200px] min-w-[200px] my-4">
                <img :src="store.messageImage" alt="" class="min-h-[200px] min-w-[200px] mx-auto">
            </div>
            <hr>
            <div class="py-4">
                <button class="bg-custom-secondary w-full py-2 rounded-md text-white" @click="store.hideImageViewer">Close</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useMainStore } from '@/mainStore';
import { onClickOutside } from '@vueuse/core';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { ref } from 'vue';
const store = useMainStore();
let imageViewerModalRef = ref(null)

onClickOutside(imageViewerModalRef, store.hideImageViewer);
</script>
